




























































import { Component, Vue } from "vue-property-decorator";
import HeroComponent from "@/components/Shared/HeroComponent.vue";
import SectionComponent from "@/components/Shared/SectionComponent.vue";
import Abonnements from "@/components/AbonnementNolan/Abonnements.vue";

@Component({
  components: { HeroComponent, SectionComponent, Abonnements },
})
export default class AbonnementNolan extends Vue {}
