import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";

// SCSS
import "./assets/scss/style.scss";
import "animate.css/animate.min.css";

// VUESAX
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";
Vue.use(Vuesax, {
  colors: {
    primary: "#c99f67",
  },
});

// FIREBASE
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDl4m4OV9az1p4jN0T6WVZfLDi_bwk7qWc",
  authDomain: "bastet-nolanwonder.firebaseapp.com",
  projectId: "bastet-nolanwonder",
  storageBucket: "bastet-nolanwonder.appspot.com",
  messagingSenderId: "888900096850",
  appId: "1:888900096850:web:5c253d42e51ded9fb8e08b",
  measurementId: "G-HFRPWCYQ3Q",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// VUE

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
