import { Actualite, InputSelect } from "@/models";
import ActualiteService from "@/services/actualite.services";
import { Module, VuexModule, Mutation, Action } from "vuex-class-modules";

@Module
export class Actualites extends VuexModule {
  private _actualites: Actualite[] | null = null;
  private _categories: InputSelect[] | null = null;
  private _lastActualite: Actualite | null = null;

  @Mutation
  setActualites(actualites: Actualite[]): void {
    this._actualites = actualites;
  }

  @Mutation
  setLastActualite(actualite: Actualite): void {
    this._lastActualite = actualite;
  }
  @Mutation
  setCategories(actualites: InputSelect[]): void {
    this._categories = actualites;
  }

  @Action
  async getActualites(): Promise<any> {
    try {
      const response = await ActualiteService.All();
      this.setActualites(response);
      return true;
    } catch {
      return false;
    }
  }

  @Action
  async getLastActualite(): Promise<any> {
    try {
      const response = await ActualiteService.Last();
      this.setLastActualite(response);
      return true;
    } catch {
      return false;
    }
  }

  @Action
  async getCategories(): Promise<any> {
    try {
      const response = await ActualiteService.Categories();
      this.setCategories(response);
      return true;
    } catch {
      return false;
    }
  }

  get actualites(): Actualite[] | null {
    return this._actualites;
  }

  get lastActualites(): Actualite | null {
    return this._lastActualite;
  }

  get categories(): InputSelect[] | null {
    return this._categories;
  }
}
