








import { Component, Vue } from "vue-property-decorator";
import NavigationTop from "@/components/navigations/Top.vue";
import NavigationBottom from "@/components/navigations/Bottom.vue";

@Component({
  components: {
    NavigationTop,
    NavigationBottom,
  },
})
export default class App extends Vue {}
