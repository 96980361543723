















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SectionComponent extends Vue {
  @Prop() subtitle!: string | null;
  @Prop() title!: string | null;
  @Prop() toLink?: string | null;
  @Prop({ default: "En savoir plus" }) toLabel?: string | null;
}
