import { Http } from "@/utils/http";

export default class ContactService {
  public static async Contact(
    email: string,
    prenom: string,
    nom: string,
    message: string
  ): Promise<boolean> {
    const targetUrl = `https://api.nolanwonder.fr/?task=contact&email=${email}&prenom=${prenom}&nom=${nom}&message=${message}`;
    const result = await Http.instance.get(targetUrl);
    if (result.data.code === "200") return true;
    else return false;
  }
}
