








import { Component, Vue } from "vue-property-decorator";
import Hero from "@/components/Home/Hero.vue";
import SectionBienvenue from "@/components/Home/SectionBienvenue.vue";
import SectionRaisonDetre from "@/components/Home/SectionRaisonDetre.vue";

@Component({
  components: { Hero, SectionBienvenue, SectionRaisonDetre },
})
export default class Home extends Vue {}
