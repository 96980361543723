



































































import { Component, Vue } from "vue-property-decorator";
import HeroComponent from "@/components/Shared/HeroComponent.vue";
import EncadrantElement from "@/components/Encadrant/Encadrant.vue";
import Recrutement from "@/components/Encadrant/Recrutement.vue";

@Component({
  components: { HeroComponent, EncadrantElement, Recrutement },
})
export default class Encadrant extends Vue {}
