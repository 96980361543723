import { Actualite, InputSelect } from "@/models";
import { Http } from "@/utils/http";

export default class ActualiteService {
  public static async All(): Promise<Actualite[]> {
    const targetUrl = `https://api.nolanwonder.fr/?task=actualites`;
    const result = await Http.instance.get(targetUrl);
    return result.data.data;
  }
  public static async Last(): Promise<Actualite> {
    const targetUrl = `https://api.nolanwonder.fr/?task=actualites&last=true`;
    const result = await Http.instance.get(targetUrl);
    return result.data.data[0];
  }
  public static async Categories(): Promise<InputSelect[]> {
    const targetUrl = `https://api.nolanwonder.fr/?task=actualitesCategorie`;
    const result = await Http.instance.get(targetUrl);
    return result.data.data;
  }
}
