



































import ContactService from "@/services/contact.service";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SectionForm extends Vue {
  private firstname = "";
  private lastname = "";
  private email = "";
  private message = "";
  private cgu = false;
  $vs: any;

  private async sendContact(): Promise<void> {
    if (
      this.firstname &&
      this.lastname &&
      this.email &&
      this.message &&
      this.cgu
    ) {
      if (
        await ContactService.Contact(
          this.email,
          this.firstname,
          this.lastname,
          this.message
        )
      ) {
        this.$vs.notification({
          duration: 10000,
          position: "bottom-center",
          title: "Demande de contact envoyée",
          text: `Un animateur Nolan vous répondra prochainement sur votre adresse e-mail`,
        });
        this.firstname = "";
        this.lastname = "";
        this.email = "";
        this.message = "";
        this.cgu = false;
      } else {
        this.$vs.notification({
          duration: 10000,
          position: "bottom-center",
          title: "Demande de contact échouée",
          text: `Un problème est survenue. Nous n'avons pas réussi à effectuer la demande de contact. Merci de réesayer plus tard.`,
        });
      }
    } else {
      this.$vs.notification({
        duration: 10000,
        position: "bottom-center",
        title: "Merci de remplir l'ensemble des champs",
        text: `Il nous faut votre prénom, nom, adresse e-mail ainsi que votre message et l'acceptation des C.G.U pour effectuer une demande de contact`,
      });
    }
  }
}
