



















import { Component, Vue } from "vue-property-decorator";
import OffreDecouverte from "./OffreDecouverte.vue";
import OffreNolan from "./OffreNolan.vue";
import OffreFamille from "./OffreFamille.vue";

@Component({
  components: { OffreDecouverte, OffreNolan, OffreFamille },
})
export default class SectionAbonnement extends Vue {}
