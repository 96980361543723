




























































































































































































































import { Component, Vue } from "vue-property-decorator";
import HeroComponent from "@/components/Shared/HeroComponent.vue";

@Component({
  components: { HeroComponent },
})
export default class Encadrant extends Vue {}
