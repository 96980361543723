



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class HeroComponent extends Vue {
  @Prop() title!: string | null;
  @Prop() subtitle!: string | null;
  @Prop({ default: true }) withWaves?: boolean;
  @Prop({ default: false }) dark?: boolean;
}
