import Vue from "vue";
import Vuex, { Store } from "vuex";
import { Actualites } from "./modules/actualites.module";

//Modules

Vue.use(Vuex);

export const store = new Store({});
export const actualites = new Actualites({ store, name: "actualites" });
