











































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class NavigationTop extends Vue {
  private scrollPosition = 0;
  private isSticky = false;

  get route(): string {
    return this.$route.path;
  }

  created(): void {
    window.addEventListener("scroll", this.handleScroll);
  }
  handleScroll(): void {
    this.scrollPosition = window.scrollY;
    if (this.scrollPosition > 0) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
    this.toggleState = false;
  }

  private toggleState = false;

  private toggleRoutes(): void {
    this.toggleState = !this.toggleState;
  }

  @Watch("$route", { immediate: true, deep: true })
  private onUrlChange(): void {
    this.toggleState = false;
  }
}
