import axios, { AxiosInstance } from "axios";

export class Http {
  static instance: AxiosInstance = axios.create({});

  // static sync(): AxiosInstance {
  //   this.addToken();
  //   return this.instance;
  // }

  // static addToken(): AxiosInstance {
  //   this.instance.defaults.headers["Authorization"] =
  //     "Bearer " + LocalStorageApp.getItem("app.token");
  //   return this.instance;
  // }

  static withUrl(url: string): AxiosInstance {
    this.instance.defaults.baseURL = url;
    return this.instance;
  }
}
