



























































































































































import { Actualite } from "@/models";
import { actualites } from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SectionBienvenue extends Vue {
  private lastActualite: Actualite | null = null;

  mounted(): void {
    this.getLastActualite();
  }

  private async getLastActualite(): Promise<void> {
    if (await actualites.getLastActualite()) {
      this.lastActualite = actualites.lastActualites;
    }
  }
}
