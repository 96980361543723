



























import { Component, Vue } from "vue-property-decorator";
import HeroComponent from "@/components/Shared/HeroComponent.vue";
import ContactElements from "@/components/Contact/Contact.vue";
import FormContact from "@/components/Contact/Form.vue";
import Concept from "@/components/Contact/Concept.vue";

@Component({
  components: { HeroComponent, ContactElements, FormContact, Concept },
})
export default class Contact extends Vue {}
