import Concept from "@/views/Concept.vue";
import Home from "@/views/Home.vue";
import Encadrant from "@/views/Encadrant.vue";
import Professionnels from "@/views/Professionnels.vue";
import MentionsLegales from "@/views/MentionsLegales.vue";
import AbonnementNolan from "@/views/AbonnementNolan.vue";
import Actualites from "@/views/Actualites.vue";
import Contact from "@/views/Contact.vue";
import Cgu from "@/views/CGU.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Accueil - Nolan",
    },
  },
  {
    path: "/le-concept",
    name: "Concept",
    component: Concept,
    meta: {
      title: "Le concept - Nolan",
    },
  },
  {
    path: "/encadrants",
    name: "Encadrant",
    component: Encadrant,
    meta: {
      title: "L'encadrant - Nolan",
    },
  },
  {
    path: "/professionnels",
    name: "Professionnels",
    component: Professionnels,
    meta: {
      title: "Professionnels - Nolan",
    },
  },
  {
    path: "/inscription",
    name: "AbonnementNolan",
    component: AbonnementNolan,
    meta: {
      title: "Inscription - Nolan",
    },
  },
  // {
  //   path: "/actualites",
  //   name: "Actualites",
  //   component: Actualites,
  //   meta: {
  //     title: "Actualités - Nolan",
  //   },
  // },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Contact - Nolan",
    },
  },
  {
    path: "/mentions-legales",
    name: "MentionsLegales",
    component: MentionsLegales,
    meta: {
      title: "Mentions légales - Nolan",
    },
  },
  {
    path: "/cgu",
    name: "Cgu",
    component: Cgu,
    meta: {
      title: "Conditions Générales d'Utilisation - Nolan",
    },
  },
  {
    path: "/out/youtube",
    name: "Youtube",
    beforeEnter() {
      window.open(
        "https://www.youtube.com/channel/UCxhyJ8tI3qc5l9xJZ3KJk3Q",
        "_blank"
      );
    },
    meta: {
      title: "Redirection vers Youtube - Nolan",
    },
  },
  {
    path: "/out/instagram",
    name: "Instagram",
    beforeEnter() {
      window.open("https://www.instagram.com/nolanwonder_france/", "_blank");
    },
    meta: {
      title: "Redirection vers Instagram - Nolan",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.afterEach((to) => {
  const title = to?.meta?.title ?? "";
  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router;
