














import { Component, Vue } from "vue-property-decorator";
import HeroComponent from "@/components/Shared/HeroComponent.vue";
import EspritNolan from "@/components/Concept/EspritNolan.vue";
import Methode from "@/components/Concept/Methode.vue";
import PhasesFondamentales from "@/components/Concept/PhasesFondamentales.vue";
import Maniere from "@/components/Concept/Maniere.vue";
import Expension from "@/components/Concept/Expension.vue";

@Component({
  components: {
    HeroComponent,
    EspritNolan,
    Methode,
    PhasesFondamentales,
    Maniere,
    Expension,
  },
})
export default class Concept extends Vue {}
